export const useCheckEmailCompanyScore = () => {
  // Extracted constants for better readability
  const forSaleKeywords = [
    "domain for sale", 
    "buy this domain", 
    "make offer", 
    "this domain is available", 
    "purchase this domain", 
    "domain may be for sale"
  ];

  const check = async ({ email, company_website }: { email: string; company_website: string }): Promise<boolean | string> => {
    if (!email || !company_website) {
      return 'failed: missing data';
    }

    try {
      const authFetch = useAuthFetch();

      // Parse domains more reliably
      const emailDomain = email.split('@')[1]?.toLowerCase();
      const companyWebsiteDomain = company_website
        .replace(/^(https?:\/\/)?(www\.)?/, '')
        .split('/')[0]
        .toLowerCase();

      // Validate the domains first
      if (!emailDomain || !companyWebsiteDomain || emailDomain !== companyWebsiteDomain) {
        console.log('Email domain:', emailDomain,'Company website domain:', companyWebsiteDomain);
        return 'failed: domain mismatch';
      }
      // Attempt to fetch the website using HTTPS first
      let response = await authFetch("https://" + companyWebsiteDomain, { 
        method: "GET", 
        silent: true,
      });

      // If the HTTPS request fails or returns an invalid response, fallback to HTTP
      if (typeof response !== "string" || response.trim().length === 0) {
        response = await authFetch("http://" + companyWebsiteDomain, { 
          method: "GET", 
          silent: true,
        });

        // If the HTTP request also fails, return an error
        if (typeof response !== "string" || response.trim().length === 0) {
          return 'failed: domain unreachable';
        }
      }

      // Check if it has a title tag with content
      const titleMatch = response.match(/<title>([^<]*)<\/title>/i);
      const ogTitleMatch = response.match(/<meta[^>]*name=["']og:title["'][^>]*content=["']([^"']*)["'][^>]*>/i);
      if ((!titleMatch || !titleMatch[1].trim()) && (!ogTitleMatch || !ogTitleMatch[1].trim())) {
        return 'failed: no title tag or og:title meta tag';
      }

      // Optimize keyword check by converting response to lowercase once
      const responseLower = response.toLowerCase();
      if (forSaleKeywords.some(keyword => responseLower.includes(keyword))) {
        return 'failed: domain for sale';
      }

      return true;
    } catch (e) {
      console.warn('Email company validation error:', e);
      return 'failed to validate:' + JSON.stringify(e);
    }
  };

  return {
    check,
  };
};
