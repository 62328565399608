import { Filter } from "bad-words";

export const useCheckProfanity = () => {
  const filter = new Filter();

  const additionalProfanity: string[] = [];

  filter.addWords(...additionalProfanity);

  const checkProfanity = (record: Record<string, any>): boolean | { key: string; value: string } => {
    for (const key in record) {
      if (typeof record[key] === "string") {
        if (filter.isProfane(record[key])) {
          return { key, value: record[key] };
        }
      }
    }
    return false;
  };

  return { checkProfanity };
};
